.bouton {
    color: var(--couleur-violet);
    background-color: rgb(219 177 164);
    border: solid transparent;
    border-radius: 16px;
    border-width: 0 0 4px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    font-family: din-round,sans-serif;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: .8px;
    line-height: 20px;
    margin: 0;
    outline: none;
    overflow: visible;
    padding: 13px 16px;
    text-align: center;
    touch-action: manipulation;
    transform: translateZ(0);
    transition: filter .2s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
}

.bouton:after {
    background-clip: padding-box;
    background-color: var(--couleur-beige);
    border: solid transparent;
    border-radius: 16px;
    border-width: 0 0 4px;
    bottom: -4px;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}

.bouton:focus {
    user-select: auto;
}

.bouton:hover:not(:disabled) {
    filter: brightness(1.1);
    -webkit-filter: brightness(1.1);
}

.bouton:disabled {
    cursor: auto;
    opacity: 0.5;
    pointer-events: none;
    filter: grayscale(100%);
}

.bouton:active {
    border-width: 4px 0 0;
    background: none;
}

.boutonReponse {
    text-transform: uppercase;
    width: 49%;
}