:root {
    --couleur-beige: rgb(232 205 196);
    --couleur-violet: rgb(123 90 115);
}

body {
    background-image: url('../assets/fond.png');
    background-repeat: repeat;
    background-size: cover;
    background-position: center;
    margin: auto;
    color: var(--couleur-beige);
    height: 100vh;
    font-family: "Berlin Sans FB", sans-serif;
}

.divPrincipale {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

#root {
    height: 100%;
}

.divContent {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.divViolette {
    background-color: var(--couleur-violet);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
    text-align: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    min-height: 300px;
    min-width: 300px;
    border-radius: 50px;
}

#divBoutons {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    margin: 5px;
    align-items: center;
}

.divLigneBoutons {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px;
}

.lien {
    padding: 15px;
    color: var(--couleur-beige);
    font-size: 18px;
    text-align: center;
}

.lien:hover {
    filter: sepia(2.0);
    -webkit-filter: sepia(2.0);
}

hr {
    width: 100%;
    border-color: var(--couleur-beige);
}