.header {
    background-color: rgba(107, 30, 88, 0.85);
    display: flex;
    padding: 10px 30px;
    justify-content: space-between;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.3);
}

.headerLogo {
    border-radius: 100%;
    width: 100%;
    vertical-align: middle;
}

.headerLienLogo {
    max-width: 60px;
}

.headerLien {
    padding: 15px;
    color: rgb(244 206 197);
    text-decoration: none;
    font-size: 24px;
    text-align: center;
    display: grid;
    place-items: center; 
}

.headerBouton {
    background: none;
    border: none;
}

.headerBouton i{
    width: 100%;
    color: rgb(244 206 197);
    font-size: 20px;
}

.headerBouton:hover {
    cursor: pointer;
}